import 'bootstrap/dist/css/bootstrap.css';
import './Recipe.css'
import React, {useState} from 'react';


function Recipe(props) {
    const [showMethod, setShowMethod] = useState("false");
    const [visibleItem, setVisibleItem] = useState(null);

    function ShowMethod(event) {
        setShowMethod(event.target.value)
    };

    function ChangeParentVisibilty() {
        setShowMethod("false");
        props.handleVisibilityChange("false", null, "foregroundbox", "visible");
    };
    
    React.useEffect(() => {
        if(props.recipe != null) {
            if(showMethod === "false") {
                setVisibleItem(props.recipe.Ingredients);
            }else {setVisibleItem(props.recipe.Method);}
        }
    },[props, showMethod]) 

    return (
            <div class="container-fluid container-height list-scroll">
                    <div class="row">
                        <div class="col"></div>
                        <div class="col d-flex justify-content-center">
                            <p class="text-dark h4">{props.title}</p>
                        </div>
                        <div class="col">
                            <div>
                                <button class="btn btn-outline-secondary float-end" type="button" onClick={ChangeParentVisibilty}><i class="bi bi-x-lg"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <div class="col d-flex justify-content-center align-content-center ">
                            <div class="btn-group" role="group">
                                <button value="false" type="button" class="btn btn-secondary " onClick={(e) => ShowMethod(e)}>Ingredients</button>
                                <button value="true" type="button" class="btn btn-secondary" onClick={(e) => ShowMethod(e)}>Method</button>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-8 d-inline ">
                            <ul key={visibleItem} class="list-unstyled m-4 px-2 text-wrap text-dark ">{visibleItem}</ul>
                        </div>
                        <div class="col-4 d-inline justify-content-center" style={{height : '250px'}}>
                            <img class='w-100' src={"./assets/" + props.image}/>
                        </div>
                    </div>
            </div>

    );
}
 

export default Recipe

