import './RecipeGrid.css'
import React, { useState } from "react";
import Recipe from './Recipe';
import RecipeData from '../assets/db.json'

//Splits Array given into multiple arrays of the n lenght
const arrayChunk = (arr, n) => {
  const array = arr.slice();
  const chunks = [];
  while (array.length) chunks.push(array.splice(0, n));
  return chunks;
};


function RecipeGrid (){
  const [showRecipe, setShowRecipe] = useState("false");
  const [recipe, setRecipe] = useState(null);
  const [title, setTitle] = useState(null);
  const [recipeImage, setRecipeImage] = useState(null)
  const [classdata, setClassData] = useState("foregroundbox");
  const [recipeThumbnail, setRecipeThumbnail] = useState(null);


  //need to alter this to get the recipe form a local file
  async function GetRecipe(title) {
    console.log(RecipeData)
    let data
    if(title != null) {
      RecipeData["db"].forEach((r) => {
        if(r["Name"] == title) {
          data = r;
        } 
      })
      let ingredientlist, methodlist;
      methodlist = data.Method.map((step, index) =>  <li id="RecipeItemList" key={index}>{step}</li>);
      ingredientlist = data.Ingredients.map((item, index) =>  <li id="RecipeItemList" key={index}>{item.Ingredient} : {item.Amount} {item.Units} </li>);
      setRecipe({"Ingredients": ingredientlist, "Method" : methodlist});
      setRecipeImage(data.Image)
      setTitle(title)
    };
  }

  async function ShowRecipe(desiredstate, title, backgroundclass, scrollvisibility) {
    await GetRecipe(title);
    document.body.style.overflow = scrollvisibility;
    setClassData(backgroundclass);
    setShowRecipe(desiredstate);  
  };

  React.useEffect(() => {
    if(recipeThumbnail == null) {
      //get data
      let arr = [];
      RecipeData["db"].forEach((r) => {
        arr.push({"Name": r["Name"], "Image": r["Image"]})
      })  //end
      setRecipeThumbnail(arr);
      } else {setRecipeThumbnail(recipeThumbnail)}
    }, [recipeThumbnail]);

  if (recipeThumbnail == null) {
    return (
      <div>Loading...</div>
    )
  }else {
    return (
      <div class="container">
        <div className={classdata}>
            {arrayChunk(recipeThumbnail, 3).map((row) => (
                    <div class="row">
                      {row.map((col, i) => (
                        <div class="col">
                          <div class="card text-center mt-3 px-3" style={{ width: '18rem' }}>
                            <div class="Card-header p-2 h5">{col.Name}</div>
                            <img class="Card-Img" variant="top" src={require("../../public/assets/" + col.Image)} alt={col.Name}/>
                            <div class="Card-Body p-2">
                              <button title={col.Name} desiredstate="true" href="#" onClick={(e) => ShowRecipe(e.target.desiredstate, e.target.title, "backgroundbox", "hidden")} class="btn btn-secondary mb-1">Show Recipe</button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
        </div>
        <div style={{display : showRecipe === "false" ? "none" : "flex"}} class="overlay border border-Secondary border-3 p-2 position-fixed top-50 start-50 translate-middle w-75">
          <Recipe recipe={recipe != null ? recipe : undefined} title={title} image={recipeImage} handleVisibilityChange={ShowRecipe} class='w-100'/>
        </div>
      </div>
    );
  }
}

export default RecipeGrid;