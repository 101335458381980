import './Home.css'

function Home() {
    return(
        <div class="text-center text-wrap position-absolute top-50 start-50 translate-middle">
            <p Class="fw-bold">Welcome</p>
            <p>You'll find our cookbook here.</p>
            <p>The recipes will grow over time, the recipes here are a collection of our favourites. We've had recipes lost to the ages, but we won't let that happen anymore.</p>
        </div>
    )
}

export default Home