import RecipeGrid from './components/RecipeGrid.js';
import NavigationBar from './components/NavigationBar.js';
import Recipe from './components/Recipe.js'
import 'bootstrap/dist/css/bootstrap.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    } from 'react-router-dom';
import Home from './Home.js';
import './App.css'

function App() {
  return (
    <div className="App">
      <NavigationBar/>
        <Router>
             <Routes>
                 <Route exact path='/' element={< Home />}></Route>
                 <Route exact path='/All' element={< RecipeGrid/>}></Route>
                 <Route exact path='/Recipe' element={< Recipe />}></Route>
            </Routes>
      </Router>
    </div>
  );
}

export default App;
