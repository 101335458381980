import './NavigationBar.css'
import {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';




function NavigationBar() {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(current => !current);
    };

    return (
            <nav id="navMain" class="navbar navbar-expand-lg navbar-light sticky-top bg-dark px-4 justify-content-between">
                <div class="container-fluid ">
                    <a class="navbar-brand text-light px-4" href="/">mchl.nz: Our Recipes</a>
                    <div id="blockContainer">
                        <button class="btn btn-outline-secondary" type="button" id="navButton" onClick={handleClick}>
                            <i class="bi bi-list"></i>
                        </button>
                        <div id="transitionBlock"></div>
                    </div>
                </div>
                <div id="sideMenu" style={{translate: isActive ? "0px" : "300px" }}  class="bg-dark text-white p-4" >
                    <ul class="list-unstyled">
                        <h2 key="header">Menu</h2>
                        <li key="Home"><a href="/">Home</a></li>
                        <li key="Menu"><a href="/All">All Recipes</a></li>
                    </ul>
                </div>
            </nav>
     );
}

export default NavigationBar